<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Clientes</strong>
                <v-spacer></v-spacer>
                <v-flex class="d-flex justify-center" xs12 md9>
                  <v-radio-group dense v-model="filtroStatus" row>
                    <v-radio
                      dense
                      color="blue"
                      label="Todos"
                      value="0"
                    ></v-radio>
                    <v-radio
                      dense
                      color="orange"
                      label="Instalaciones"
                      value="1"
                    ></v-radio>
                    <v-radio
                      dense
                      color="green"
                      label="Activos"
                      value="2"
                    ></v-radio>
                    <v-radio
                      dense
                      color="red"
                      label="Suspendidos"
                      value="3"
                    ></v-radio>
                    <v-radio
                      dense
                      color="indigo"
                      label="Pendiente"
                      value="4"
                    ></v-radio>
                    <v-radio
                      dense
                      color="grey"
                      label="Baja"
                      value="5"
                    ></v-radio>
                    <v-radio
                      dense
                      color="black"
                      label="Eliminados"
                      value="6"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      dense
                      @click="nuevo()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                    <v-btn
                      dense
                      @click="exportar()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-file-export</v-icon>
                      Exportar
                    </v-btn>
                    <v-menu offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          color="purple"
                          class="white--text rounded-pill ma-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ nombreZona }}
                          <v-icon rigth>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-responsive
                          id="scroll-target"
                          class="overflow-y-auto"
                          :max-height="450"
                        >
                          <v-list-item
                            v-for="z in zonas"
                            :key="z.id"
                            link
                            @click="filtrarZona(z)"
                          >
                            <v-list-item-title>{{
                              z.nombre
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-responsive>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      class="rounded-pill"
                      solo
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                v-model="selected"
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                show-select
                :single-select="singleSelect"
                :footer-props="footerProps"
              >
                <template v-slot:top>
                  <v-container grid-list-md>
                    <v-layout v-if="selected.length > 0" row wrap>
                      <v-flex xs12>
                        <v-btn
                          @click="generarFacturas()"
                          outlined
                          small
                          dark
                          color="orange"
                        >
                          <v-icon left>mdi-cash-multiple</v-icon>Generar
                          facturas
                        </v-btn>
                        <v-btn
                          @click="cambioEstadoMasivo()"
                          outlined
                          small
                          dark
                          color="green"
                        >
                          <v-icon left>mdi-wifi</v-icon>Cambiar de estado
                        </v-btn>
                        <v-btn
                          @click="moverZonaMasivo()"
                          outlined
                          small
                          dark
                          color="purple"
                        >
                          <v-icon left>mdi-tower-fire</v-icon>Mover de zona
                        </v-btn>
                        <!--
                        <v-btn
                          @click="moverRouterMasivo()"
                          outlined
                          small
                          dark
                          color="grey"
                        >
                          <v-icon left>mdi-router-wireless</v-icon>Mover de RB
                        </v-btn>
                        -->
                        <v-btn
                          @click="compartirClientes()"
                          outlined
                          small
                          dark
                          color="teal"
                        >
                          <v-icon left>mdi-share</v-icon>Compartir
                        </v-btn>
                        <v-btn
                          @click="eliminacionMasiva()"
                          outlined
                          small
                          dark
                          color="black"
                          v-if="filtroStatus != 4"
                        >
                          <v-icon left>mdi-delete</v-icon>Eliminar
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </template>
                <template v-slot:item.zona="{ item }">
                  <v-chip
                    small
                    @click="abrirZona(item.zona_id)"
                    color="purple"
                    dark
                    outlined
                  >
                    <v-icon small left>mdi-map-marker</v-icon>
                    {{ item.zona.nombre }}</v-chip
                  >
                </template>
                <template v-slot:item.contacto="{ item }">
                  <v-chip
                    v-if="item.celular"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="green darken-4"
                    @click="enviarWhats(item.celular)"
                  >
                    <v-icon left> mdi-whatsapp </v-icon>{{ item.celular }}
                  </v-chip>
                  <v-chip
                    v-if="item.telefono"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="blue darken-4"
                    @click="llamar(item)"
                  >
                    <v-icon left> mdi-phone </v-icon>{{ item.telefono }}
                  </v-chip>
                  <v-chip
                    v-if="item.correo"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="deep-orange darken-4"
                    @click="enviarCorreo(item)"
                  >
                    <v-icon left> mdi-gmail </v-icon>{{ item.correo }}
                  </v-chip>
                </template>
                <template v-slot:item.ip="{ item }">
                  <v-btn text @click="abrirIp(item.ip)" color="blue">
                    {{ item.ip ? item.ip : "Pendiente" }}
                  </v-btn>
                </template>

                <template v-slot:item.saldo="{ item }">
                  <v-chip
                    @click="verSaldo(item.id)"
                    small
                    dark
                    :color="
                      parseInt(item.saldo) == 0 ? 'light-green' : 'orange'
                    "
                    >${{ item.saldo }}</v-chip
                  >
                </template>
                <template v-slot:item.status="{ item }">
                  <v-tooltip v-if="item.eliminado == false" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        class="elevation-0"
                        @click="status(item)"
                        v-bind="attrs"
                        v-on="on"
                        :color="getColorStatus(item.status.id)"
                        dark
                      >
                        <v-icon x-small v-if="item.status_cliente_id == 1"
                          >mdi-worker</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 2"
                          >mdi-wifi</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 3"
                          >mdi-wifi-off</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 4"
                          >mdi-timer</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 5"
                          >mdi-account-off</v-icon
                        >
                        <v-icon x-small v-if="item.status_cliente_id == 6"
                          >mdi-emoticon-dead</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>{{ item.status.status }}</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        class="elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        color="black"
                        dark
                      >
                        <v-icon x-small>mdi-emoticon-dead</v-icon>
                      </v-btn>
                    </template>
                    <span> Eliminado </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="detalles(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="editarCliente(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar cliente</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="eliminarCliente(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar Cliente</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="compartir(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-share </v-icon>
                      </v-btn>
                    </template>
                    <span>Compartir cliente</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == true" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="restaurar(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-restore </v-icon>
                      </v-btn>
                    </template>
                    <span>Restaurar Cliente</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()">
                          <v-icon pl-4>mdi-cached</v-icon> Refrescar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <actualizarStatus ref="status"></actualizarStatus>
        <moverDeZona ref="moverDeZona"></moverDeZona>
        <moverDeRouter ref="moverClienteRouter"></moverDeRouter>
        <exportar ref="refExportar"></exportar>
        <compartir ref="refCompartir"></compartir>
        <editarCliente ref="refEditarCliente"></editarCliente>
        <mensajeWhats ref="refWhats"></mensajeWhats>
        <mensajeCorreo ref="refCorreo"></mensajeCorreo>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <facturasMasivas ref="facturasMasivas"></facturasMasivas>
        <statusMasivo ref="statusMasivo"></statusMasivo>
        <eliminacionMasiva ref="eliminacionMasiva"></eliminacionMasiva>
      </div>
    </base-component>
  </div>
</template>

<script>
import editarCliente from "@/js/components/clientes/listar_clientes/editarCliente.vue";
import mensajeWhats from "@/js/components/clientes/listar_clientes/mensajeWhatsapp.vue";
import compartir from "@/js/components/clientes/listar_clientes/compartir.vue";
import actualizarStatus from "@/js/components/clientes/listar_clientes/actualizarEstadoCliente.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import mensajeCorreo from "@/js/components/clientes/detalles/notificaciones/NuevoCorreo.vue";
import LocalStorage from "@/js/LocalStorage.js";
import facturasMasivas from "@/js/components/acciones_masivas/facturasMasivas.vue";
import statusMasivo from "@/js/components/acciones_masivas/estadoClienteMasivo.vue";
import eliminacionMasiva from "@/js/components/acciones_masivas/eliminacionClienteMasiva.vue";
import moverDeZona from "@/js/components/acciones_masivas/moverZonaClienteMasiva.vue";
import moverDeRouter from "@/js/components/acciones_masivas/moverDeRouterMasivoCilente.vue";

export default {
  components: {
    editarCliente,
    exportar,
    mensajeWhats,
    mensajeCorreo,
    actualizarStatus,
    facturasMasivas,
    statusMasivo,
    eliminacionMasiva,
    moverDeZona,
    moverDeRouter,
    compartir,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Nombre", value: "FullName", sortable: false },
        { text: "Zona", value: "zona", sortable: false },
        { text: "Contacto", value: "contacto", sortable: false },
        { text: "IP", value: "ip", sortable: false },
        { text: "Saldo pendiente", value: "saldo", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      footerProps: {
        showFirstLastPage: true,
        firstIcon: "mdi-format-horizontal-align-left",
        lastIcon: "mdi-format-horizontal-align-right",
        prevIcon: "mdi-chevron-left",
        nextIcon: "mdi-chevron-right",
        itemsPerPageAllText: "Todos",
        itemsPerPageText: "Registros por página:",
        itemsPerPageOptions: [10, 50, 100, 500, -1],
      },
      whatsapp: "",
      filtroStatus: "0",
      zonas: [],
      zona: 0,
      nombreZona: "Zona",
    };
  },
  mounted() {
    var filtroActual = LocalStorage.get("FILTRO_CLIENTES");
    if (filtroActual) {
      this.filtroStatus = filtroActual;
    }
    var filtroActualZona = LocalStorage.get("FILTRO_CLIENTES_ZONA");
    if (filtroActualZona) {
      this.zona = filtroActualZona;
    }
    var buscador = LocalStorage.get("BUSCADOR_CLIENTES");
    if (buscador) {
      this.search = buscador;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
        this.getZonas();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_CLIENTES", val);
    },
    filtroStatus(val) {
      LocalStorage.set("FILTRO_CLIENTES", val);
      this.getDatos();
    },
    zona(val) {
      LocalStorage.set("FILTRO_CLIENTES_ZONA", val);
    },
  },
  computed: {},
  methods: {
    getZonas() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zonas/empresa/todas`,
          data: {},
        })
        .then((result) => {
          this.zonas = result.data.zonas;
          this.zonas.push({ id: 0, nombre: "Todas" });

          var zona_actual_id = LocalStorage.get("FILTRO_CLIENTES_ZONA");
          if (zona_actual_id) {
            const zonaEncontrada = this.zonas.find(
              (zona) => zona.id == zona_actual_id
            );
            this.nombreZona = zonaEncontrada ? zonaEncontrada.nombre : "Error";
          }
        });
    },
    getDatos() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `clientes/table/data`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            filtro_status: this.filtroStatus,
            zona: parseInt(this.zona),
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.clientes.data;
          this.totalElementos = result.data.clientes.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    editarCliente(item) {
      this.ClienteActualizar = item;
      const cliente = Object.assign({}, item);
      this.$refs.refEditarCliente.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.ClienteActualizar),
            1,
            confirm
          );
        }
      });
    },
    status(item) {
      this.ClienteActualizar = item;
      const cliente = Object.assign({}, item);
      this.$refs.status.cargarDatos(cliente).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.ClienteActualizar),
            1,
            confirm
          );
        }
      });
    },
    eliminarCliente(item) {
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `cliente/` + item.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          }
        });
    },
    restaurar(item) {
      let msg = "¿Está seguro de restaurar este cliente?";
      let data = { selected: [item] };

      this.$refs.confirmar
        .open("Confirmación", msg, {
          color: "dark",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnSave";
            let endpoint = "cliente/restaurar/" + item.id;
            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: data,
              })
              .then((result) => {
                this.elementos.splice(
                  this.elementos.indexOf(item),
                  1,
                  result.data.cliente
                );
              });
          }
        });
    },
    filtrarZona(zona) {
      this.nombreZona = zona.nombre;
      this.zona = zona.id;
      this.getDatos();
    },
    getColorStatus(item) {
      if (item == 1) return "orange";
      else if (item == 2) return "green";
      else if (item == 3) return "red";
      else if (item == 4) return "indigo";
      else if (item == 5) return "grey";
      else if (item == 6) return "black";
      else return "black";
    },
    nuevo() {
      document.location.href = "/cliente/registro/instalacion";
    },
    generarFacturas() {
      this.$refs.facturasMasivas
        .cargarDatos(this.selected)
        .then((resultado) => {
          if (resultado) {
            this.getDatos();
            this.selected = [];
          }
        });
    },
    cambioEstadoMasivo() {
      this.$refs.statusMasivo.cargarDatos(this.selected).then((resultado) => {
        if (resultado) {
          this.getDatos();
          this.selected = [];
        }
      });
    },
    moverZonaMasivo() {
      this.$refs.moverDeZona.cargarDatos(this.selected).then((resultado) => {
        if (resultado) {
          this.getDatos();
          this.selected = [];
        }
      });
    },
    moverRouterMasivo() {
      this.$refs.moverClienteRouter
        .cargarDatos(this.selected)
        .then((resultado) => {
          if (resultado) {
            this.getDatos();
            this.selected = [];
          }
        });
    },
    eliminacionMasiva() {
      this.$refs.eliminacionMasiva
        .cargarDatos(this.selected)
        .then((resultado) => {
          if (resultado) {
            this.getDatos();
            this.selected = [];
          }
        });
    },
    compartir(cliente) {
      var mensaje =
        "*" +
        cliente.FullName +
        "*" +
        "%0A%0A" +
        " *Celular:*%20%20%20%20" +
        cliente.celular +
        "%0A" +
        " *Telefono:*%20%20%20%20" +
        cliente.telefono +
        "%0A" +
        " *Correo:*%20%20%20%20%20" +
        cliente.correo +
        "%0A" +
        " *Ip:*%20%20%20%20%20%20%20" +
        cliente.ip +
        "%0A" +
        " *Dia de pago:*%20%20%20%20" +
        cliente.dia_pago +
        "%0A" +
        " *Saldo pend.:*%20%20%20" +
        "$" +
        cliente.saldo +
        "%0A" +
        " *Estado:*%20%20%20%20%20%20" +
        cliente.status.status +
        "%0A" +
        "%0A" +
        "_.:: INNOVAISP ::._";

      this.$refs.refCompartir.cargarDatos(mensaje);
    },
    compartirClientes() {
      var mensaje = "";
      for (let index = 0; index < this.selected.length; index++) {
        let cliente = this.selected[index];
        mensaje +=
          "*" +
          cliente.FullName +
          "*" +
          "%0A%0A" +
          " *Celular:*%20%20%20%20" +
          cliente.celular +
          "%0A" +
          " *Telefono:*%20%20%20%20" +
          cliente.telefono +
          "%0A" +
          " *Correo:*%20%20%20%20%20" +
          cliente.correo +
          "%0A" +
          " *Ip:*%20%20%20%20%20%20%20" +
          cliente.ip +
          "%0A" +
          " *Dia de pago:*%20%20%20%20" +
          cliente.dia_pago +
          "%0A" +
          " *Saldo pend.:*%20%20%20" +
          "$" +
          cliente.saldo +
          "%0A" +
          " *Estado:*%20%20%20%20%20%20" +
          cliente.status.status +
          "%0A" +
          "-------------------------------" +
          "%0A%0A";
      }
      mensaje += "_.:: INNOVAISP ::._";

      this.$refs.refCompartir.cargarDatos(mensaje);
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("clientes");
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    enviarWhats(item) {
      this.$refs.refWhats.cargarDatos(item);
    },
    llamar(item) {
      document.location.href = "tel:" + item.telefono;
    },
    enviarCorreo(item) {
      this.$refs.refCorreo.cargarDatos(item);
    },
    abrirZona(id) {
      document.location.href = "/detalles/zona/" + id;
    },
    detalles(item) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + item.id;
    },
    verSaldo(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 2);
      document.location.href = "detalles/cliente/" + id;
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
  },
};
</script>
