<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex xs12 md6>
            <v-card-actions>
              <v-radio-group dense v-model="estadoPago" row>
                <v-radio dense color="blue" label="Todos" value="0"></v-radio>
                <v-radio
                  dense
                  color="green"
                  label="Liquidado"
                  value="1"
                ></v-radio>
                <v-radio
                  dense
                  color="orange"
                  label="Pendiente"
                  value="2"
                ></v-radio>
                <v-radio
                  dense
                  color="purple"
                  label="Promesa"
                  value="3"
                ></v-radio>
              </v-radio-group>
            </v-card-actions>
          </v-flex>
        </v-layout>

        <v-flex xs12 md12>
          <v-card class="cool pa-3" outlined>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md3>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Liquidado</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad: {{ liquidadosTotal }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action class="green--text">
                      {{ liquidados }}
                    </v-list-item-action>
                  </v-list-item>
                </v-flex>
                <v-flex xs12 md3>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Pendientes</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad: {{ pendientesTotal }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action class="orange--text">
                      {{ pendientes }}
                    </v-list-item-action>
                  </v-list-item>
                </v-flex>
                <v-flex xs12 md3>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Promesa</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad: {{ promesasTotal }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action class="indigo--text">
                      {{ promesas }}
                    </v-list-item-action>
                  </v-list-item>
                </v-flex>
                <v-flex xs12 md3>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Retrasados</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad: {{ retrasadosTotal }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action class="red--text">
                      {{ retrasados }}
                    </v-list-item-action>
                  </v-list-item>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                @click="nuevo()"
                class="rounded-pill pa-3 ma-1"
                color="red"
                dark
              >
                <v-icon left>mdi-plus</v-icon>
                Nuevo
              </v-btn>
              <v-btn
                @click="exportar()"
                class="rounded-pill pa-3 ma-1"
                color="red"
                dark
              >
                <v-icon left>mdi-download</v-icon>
                Exportar
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-text-field
                    solo
                    dense
                    class="rounded-pill"
                    v-model="search"
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscador"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [10, 500, 1000, 5000, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.monto="{ item }">
                  ${{ item.monto }}
                </template>
                <template v-slot:item.descuento="{ item }">
                  <p class="success--text">
                    <v-icon small color="success"
                      >mdi-arrow-up-bold-circle-outline</v-icon
                    >${{ item.extra }}
                  </p>
                  <p class="red--text">
                    <v-icon small color="red"
                      >mdi-arrow-down-bold-circle-outline</v-icon
                    >${{ item.descuento }}
                  </p>
                </template>
                <template v-slot:item.correspondencia="{ item }">
                  {{ item.mes.mes }}<br />{{ item.anio }}
                </template>
                <template v-slot:item.estado="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="elevation-0"
                        v-bind="attrs"
                        v-on="on"
                        :color="calcularColor(item.status_pago_id)"
                        dark
                        @click="detalles(item)"
                      >
                        <v-icon left v-if="item.status_pago_id == 1"
                          >mdi-cash</v-icon
                        >
                        <v-icon left v-if="item.status_pago_id == 2"
                          >mdi-timer</v-icon
                        >
                        <v-icon left v-if="item.status_pago_id == 3"
                          >mdi-timer-off</v-icon
                        >
                        ${{ item.monto }}
                      </v-chip>
                    </template>
                    <span>{{ item.estado.status }} </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.retraso="{ item }">
                  <strong v-if="item.retraso == 0" class="green--text title">{{
                    item.retraso
                  }}</strong>
                  <strong v-else class="red--text">{{ item.retraso }}</strong>
                </template>
                <template v-slot:item.detalles="{ item }">
                  <div v-if="item.status_pago_id == 1">
                    <div>
                      {{ "Pago: " + item.tipo.tipo }}
                    </div>
                    {{ item.fecha + " - " + item.hora }}
                  </div>
                  <p v-if="item.detalles">
                    {{ item.detalles }}
                  </p>
                </template>
                <template v-slot:item.cajero="{ item }">
                  <v-chip
                    v-if="item.persona"
                    outlined
                    dark
                    small
                    color="orange"
                    @click="abrirCajero(item.persona.id)"
                    ><v-icon left>mdi-worker</v-icon>
                    {{ item.persona.FullName }}</v-chip
                  >
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="detalles(item)" icon v-on="on">
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.status_pago_id == 1" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="ticketCalor(item)" icon v-on="on">
                        <v-icon small>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Ticket</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="recibo(item)" icon v-on="on">
                        <v-icon small>mdi-file-document</v-icon>
                      </v-btn>
                    </template>
                    <span>Recibo</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="editar(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn small @click="eliminar(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.eliminado" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        dense
                        small
                        @click="restaurar(item)"
                        :color="item.eliminado == true ? 'red' : 'green'"
                        v-on="on"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>mdi-backup-restore</v-icon>
                      </v-btn>
                    </template>
                    <span>Restaurar</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row>
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <v-card class="cool pa-2" outlined>
            <v-card-text>
              <v-layout row wrap>
                <strong>Inversion para el cliente</strong>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-list-item three-line>
                    <v-list-item-avatar
                      size="40"
                      color="green"
                      class="shadow-lg"
                    >
                      <v-icon dark>mdi-arrow-up-bold-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-list-item-subtitle
                          >Mensualidades</v-list-item-subtitle
                        >

                        <strong>{{ liquidados }}</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs12 md6>
                  <v-list-item three-line>
                    <v-list-item-avatar
                      size="40"
                      color="green"
                      class="shadow-lg"
                    >
                      <v-icon dark>mdi-arrow-up-bold-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-list-item-subtitle
                          >Tickets pagados</v-list-item-subtitle
                        >

                        <strong>{{ formatoDinero(tickets) }}</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-list-item three-line>
                    <v-list-item-avatar
                      size="40"
                      color="green"
                      class="shadow-lg"
                    >
                      <v-icon dark>mdi-arrow-up-bold-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-list-item-subtitle
                          >Instalacion pago</v-list-item-subtitle
                        >

                        <strong>{{ formatoDinero(instalacion) }}</strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>

                <v-flex xs12 md6>
                  <v-list-item three-line>
                    <v-list-item-avatar size="40" color="red" class="shadow-lg">
                      <v-icon dark>mdi-arrow-down-bold-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-list-item-subtitle
                          >Costo del cliente</v-list-item-subtitle
                        >
                        <v-layout row wrap>
                          <strong class="ml-3">{{
                            formatoDinero(adquisicion)
                          }}</strong>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="abrirDialog('Aumentar')"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-arrow-up-drop-circle-outline
                              </v-icon>
                            </template>
                            <span>Aumentar costo</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="abrirDialog('Disminuir')"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-arrow-down-drop-circle-outline
                              </v-icon>
                            </template>
                            <span>Disminuir costo</span>
                          </v-tooltip>
                        </v-layout>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 md6>
          <v-card class="cool ma-2 pa-3" :color="colorBalance" dark outlined>
            <v-row>
              <v-col cols="12" sm="6">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div><strong>Retorno de inversión</strong></div>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        class="d-flex justify-center mt-4"
                      >
                        <v-progress-circular
                          rotate="360"
                          size="100"
                          width="12"
                          :value="retorno"
                          color="teal lighten-5"
                        >
                          {{ retorno }}%
                        </v-progress-circular>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-list-item-subtitle
                          class="d-flex justify-center mt-10"
                        >
                          <v-icon>mdi-arrow-up-bold</v-icon>
                          {{ formatoDinero(total) }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle class="d-flex justify-center">
                          <v-icon>mdi-arrow-down-bold</v-icon>
                          {{ formatoDinero(adquisicion) }}</v-list-item-subtitle
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" sm="5" align="center">
                <v-list-item two-line class="mt-10">
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1">
                      {{ formatoDinero(balance) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Ganancia del cliente</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="dialog" max-width="300px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title>
            {{ titulo }}
          </v-card-title>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            dense
            @submit.prevent="cambiarCosto()"
          >
            <v-card-text>
              <p v-if="titulo == 'Aumentar'">
                Asigna un monto que hayas gastado por el cliente ejemplo alguna
                modem o router extra o cable, etc.
              </p>

              <v-text-field
                label="Monto"
                v-model="monto"
                :rules="generalRegla"
                type="number"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                class="rounded-pill pa-3"
                color="primary"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <formEditarPago ref="refEditarPago"></formEditarPago>
    <formPago ref="refPago"></formPago>
    <exportar ref="refExportar"></exportar>
  </div>
</template>

<script>
import formPago from "@/js/components/pagos/todos/nuevoPago.vue";
import formEditarPago from "@/js/components/pagos/todos/editarPago.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import aviso_whats from "@/js/components/pagos/registrar_pago/aviso_whatsapp.vue";
import jsPDF from "jspdf";
import Common from "@/js/Common.js";
import LocalStorage from "@/js/LocalStorage.js";

import "jspdf-autotable";

export default {
  components: {
    formPago,
    formEditarPago,
    exportar,
    aviso_whats,
  },
  data() {
    return {
      estadoPago: "0",
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "Folio", align: "start", value: "id" },
        { text: "Estado y cantidad", value: "estado", sortable: false },
        { text: "Extra y descuento", value: "descuento", sortable: false },
        { text: "Mes y año", value: "correspondencia", sortable: false },
        { text: "Servicio", value: "servicio.nombre", sortable: false },
        { text: "Dias de Retraso", value: "retraso", sortable: false },
        { text: "Cajero", value: "cajero", sortable: false },
        { text: "Detalles", value: "detalles", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
      liquidados: 0,
      liquidadosTotal: 0,
      pendientes: 0,
      pendientesTotal: 0,
      promesas: 0,
      promesasTotal: 0,
      retrasados: 0,
      retrasadosTotal: 0,
      pagosTotal: 0,
      tickets: 0,
      instalacion: 0,
      total: 0,
      adquisicion: 0,
      retorno: 0,
      balance: 0,
      dialog: false,
      titulo: "",
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      valid: true,
      monto: 1,
      colorBalance: "light-blue darken-3",
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    var filtroActual = LocalStorage.get("FILTRO_PAGOS_CLIENTE");
    if (filtroActual) {
      this.estadoPago = filtroActual;
    }
    var buscador = LocalStorage.get("BUSCADOR_PAGOS_CLIENTE");
    if (buscador) {
      this.search = buscador;
    }
    this.getInformacion();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    estadoPago(val) {
      LocalStorage.set("FILTRO_PAGOS_CLIENTE", val);
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_PAGOS_CLIENTE", val);
    },
    cliente(val) {
      if (val != null) {
        this.getDatos();
      }
    },
  },
  computed: {
    cliente() {
      return this.$store.getters.getShowCliente;
    },
  },
  methods: {
    getDatos() {
      if (this.cliente == null) return;
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/cliente/tabla`,
          data: {
            id: this.cliente.id,
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.pagos.data;
          this.totalElementos = result.data.pagos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
                this.getInformacion();
              });
          } else {
          }
        });
    },
    nuevo() {
      const cliente = Object.assign({}, this.$store.getters.getShowCliente);
      this.$refs.refPago.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
          this.getInformacion();
        }
      });
    },
    editar(elemento) {
      this.PagoActualizar = elemento;
      const pago = Object.assign({}, elemento);
      this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.PagoActualizar),
            1,
            confirm
          );
          this.getInformacion();
        }
      });
    },

    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `pago/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },

    calcularColor(id) {
      if (id == 1) {
        return "green";
      }
      if (id == 2) {
        return "orange";
      }
      if (id == 3) {
        return "purple";
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("pagosCliente/" + this.cliente.id);
    },
    detalles(item) {
      document.location.href = "detalles/pago/" + item.id;
    },
    formatoDinero(valor) {
      return Common.formatMoney(valor, 2);
    },

    recibo(item) {
      let pdfName = "Recibo-" + Common.formatoFecha(this.hoy);
      var doc = new jsPDF();

      var nombre =
        item.cliente.nombre +
        " " +
        item.cliente.apellido_paterno +
        " " +
        item.cliente.apellido_materno;
      var direccion = item.cliente.direccion;
      var colonia = item.cliente.colonia;
      var municipio = item.cliente.municipio;
      var telefono = item.cliente.telefono;
      var celular = item.cliente.celular ? item.cliente.celular : "";
      var correo = item.cliente.correo;
      var estado = item.estado.status;
      var status = item.status_pago_id;
      var servicio = item.servicio.nombre;
      var tipoServicio = item.servicio.tipo.tipo;
      var detalles = item.servicio.detalles;
      var bajada = item.servicio.bajada;
      var subida = item.servicio.subida;
      var descuento = item.descuento;
      var extra = item.extra;
      var tipo = item.tipo ? item.tipo.tipo : "";
      var fecha = item.fecha ? item.fecha : "";
      var hora = item.hora ? item.fecha : "";
      var detallePago = item.detalles;
      var mes = item.mes.mes;
      var monto = item.monto;
      var creado = item.created_at;
      var logo = item.empresa.logo ? item.empresa.logo : "";

      doc.setFont("arial");
      doc.setFontSize(12);
      var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      var pageCenter = pdfInMM / 2;

      var img1 = new Image();
      img1.src = "img/recursos/tune.png";
      doc.addImage(img1, "png", 0, -2, 290, 20);
      if (logo) {
        var img2 = new Image();
        img2.src = logo;
        doc.addImage(img2, "png", 15, 25, 20, 20);
      }

      doc.setFont("helvetica");
      doc.setFontSize(15);

      var cabecera = "RECIBO DE PAGO";
      var linea = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
      doc.text(linea, pageCenter, 40, "center");

      doc.setFontSize(14);
      doc.setFont("arial");
      doc.text(15, 58, "Cliente: ");

      doc.setFontSize(12);

      doc.text(15, 65, nombre);
      doc.text(15, 70, direccion + ", " + colonia);
      doc.text(15, 75, municipio);
      if (telefono) {
        doc.text(15, 80, "Telefono: " + telefono);
      }
      doc.text(15, 85, "Celular: " + celular);
      if (correo) {
        doc.text(15, 90, "Correo: " + correo);
      }

      doc.text(135, 65, "Estado: " + estado);

      doc.setLineWidth(1);
      doc.setDrawColor(0);
      if (status == 1) {
        doc.setFillColor(0, 255, 0);
      }
      if (status == 2) {
        doc.setFillColor(255, 0, 0);
      }
      if (status == 3) {
        doc.setFillColor(139, 0, 255);
      }
      doc.circle(150, 80, 10, "FD");

      doc.setLineWidth(1);
      doc.line(195, 100, 15, 100);
      doc.setLineWidth(0.5);
      doc.line(195, 102, 15, 102);

      doc.setFontSize(14);
      doc.text(15, 110, "Detalles del servicio: ");

      doc.text(15, 115, "Nombre: " + servicio);
      doc.text(15, 120, "Tipo: " + tipoServicio);
      doc.text(15, 125, "Detalles: " + detalles);
      if (tipo == "Internet") {
        doc.text(
          15,
          130,
          "Velocidad: " + "Bajada: " + bajada + "MB / Subida: " + subida + "MB"
        );
      }

      var head = [
        ["Descuento", "Extra", "Pago", "Fecha", "Mes", "detalles", "Monto"],
      ];
      var body = [
        [descuento, extra, tipo, fecha + "-" + hora, mes, detallePago, monto],
      ];
      doc.autoTable({
        head: head,
        body: body,
        startY: 145,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 15, right: 15 },
      });
      doc.setFontSize(14);
      let finalY = doc.autoTable.previous.finalY;
      doc.text(160, finalY + 6, "Total: $" + monto);
      doc.setFontSize(8);
      doc.text(15, 240, "Generado el: " + creado);

      //var img = new Image();
      //img.src = "img/recursos/ola1.jpg";
      //doc.addImage(img, "jpg", 0, 247, 290, 55);

      doc.save(pdfName + ".pdf");
    },
    ticketCalor(item) {
      let url = "ticket/calor/pago/" + item.id;
      window.open(url, "_blank");
    },

    abrirDialog(accion) {
      this.titulo = accion;
      this.dialog = true;
    },
    cambiarCosto() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("UsersModule/OnSave", {
            url: `cliente/modificar/adquisicion/` + this.id,
            data: {
              monto: this.monto,
              accion: this.titulo,
            },
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              this.adquisicion = result.data.adquisicion;
              this.retorno = parseInt(
                (this.total * 100) / result.data.adquisicion
              );
              if (this.retorno <= 0) {
                //rojo
                this.colorBalance = "red darken-3";
              }
              if (this.retorno >= 1 && this.retorno <= 99) {
                //azul
                this.colorBalance = "light-blue darken-3";
              }
              if (this.retorno >= 100) {
                //verde
                this.colorBalance = "green";
              }

              this.balance = parseFloat(this.total) - result.data.adquisicion;

              this.dialog = false;
            }
          });
      }
    },
    getInformacion() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cliente/retorno/inversion/` + this.id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.liquidados = Common.formatMoney(result.data.liquidados);
            this.liquidadosTotal = result.data.liquidadosTotal;
            this.pendientes = Common.formatMoney(result.data.pendientes);
            this.pendientesTotal = result.data.pendientesTotal;
            this.promesas = Common.formatMoney(result.data.promesas);
            this.promesasTotal = result.data.promesasTotal;
            this.retrasados = Common.formatMoney(result.data.retrasados);
            this.retrasadosTotal = result.data.retrasadosTotal;

            this.pagos = result.data.pagos;
            this.tickets = result.data.tickets;
            this.instalacion = result.data.instalacion;
            this.total = result.data.total;
            this.adquisicion = result.data.adquisicion;
            this.retorno = result.data.retorno;
            this.balance = result.data.balance;

            if (this.retorno <= 0) {
              //rojo
              this.colorBalance = "red darken-3";
            }
            if (this.retorno >= 1 && this.retorno <= 99) {
              //azul
              this.colorBalance = "light-blue darken-3";
            }
            if (this.retorno >= 100) {
              //verde
              this.colorBalance = "green";
            }
          }
        });
    },
    abrirCajero(id) {
      LocalStorage.set("TAB_ACTUAL_PERSONAL", 5);
      document.location.href = "vista/persona/detalles/" + id;
    },
  },
};
</script>