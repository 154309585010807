<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title>
                <strong>Detalles Torre</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-map-marker</v-icon>
                  Datos
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-router-wireless</v-icon>
                  Routers</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-volume-low</v-icon>
                  antenas</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <datos></datos>
                  </v-tab-item>
                  <v-tab-item>
                    <routers></routers>
                  </v-tab-item>
                  <v-tab-item>
                    <antenas></antenas>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import datos from "@/js/components/red/torres/detalles/datosTorre.vue";
import antenas from "@/js/components/red/torres/detalles/antenasTorre.vue";
import routers from "@/js/components/red/torres/detalles/routersTorre.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    datos,
    routers,
    antenas,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_TORRE", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_TORRE");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>