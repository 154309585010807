
<template>
  <div id="chart">
    <v-card class="cool pa-3 ma-4">
      <div v-if="mostrar">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      negocio_id: null,
      mostrar: false,
      series: [
        {
          name: "Balances",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: 1,
                  to: 1000000,
                  color: "#4CAF50",
                },
                {
                  from: -1000000,
                  to: 0,
                  color: "#F44336",
                },
              ],
            },
            columnWidth: "80%",
            borderRadius: 5,
          },
        },
        title: {
          text: "Balances de los ingresos y egresos mensuales",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Cantidad",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          title: {
            text: "Mes",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val);
            },
          },
        },
      },
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
  },
  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `negocio/balance/meses/` + id,
          data: {},
        })
        .then((result) => {
          this.series = [
            {
              name: "Balance",
              data: result.data.balances,
            },
          ];
          this.mostrar = true;
        });
    },
  },
};
</script>