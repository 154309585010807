<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-card flat>
      <v-card-title class="display-1">
        <strong>Reportes</strong>

        <v-spacer></v-spacer>
        <v-radio-group dense v-model="estadoReportes" row>
          <v-radio dense color="red" label="Pendientes" value="1"></v-radio>
          <v-radio dense color="orange" label="En proceso" value="2"></v-radio>
          <v-radio dense color="green" label="Solucionados" value="3"></v-radio>
          <v-radio dense color="black" label="Todos" value="0"></v-radio>
        </v-radio-group>
      </v-card-title>
      <v-card-subtitle>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-btn
              dense
              @click="nuevoReporte()"
              class="rounded-pill pa-3 mr-2"
              color="red"
              dark
            >
              <v-icon left>mdi-ticket-account</v-icon>
              Agregar
            </v-btn>
            <v-btn
              dense
              @click="exportar()"
              class="rounded-pill pa-3"
              color="red"
              dark
            >
              <v-icon left>mdi-file-export</v-icon>
              Exportar
            </v-btn>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md6>
            <v-text-field
              v-model="search"
              @keyup.enter="getDatos"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              solo
              rounded
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-subtitle>

      <v-card-text>
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="elementos"
          :options.sync="options"
          :server-items-length="totalElementos"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-format-horizontal-align-left',
            lastIcon: 'mdi-format-horizontal-align-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageAllText: 'Todos',
            itemsPerPageText: 'Registros por página:',
            itemsPerPageOptions: [3, 5, 10, 15, -1],
          }"
        >
          <template v-slot:item.costo="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  :color="calcularColor(item.estado_reporte_id)"
                  dark
                >
                  <v-icon small left v-if="item.estado_reporte_id == 1"
                    >mdi-timer</v-icon
                  >
                  <v-icon small left v-if="item.estado_reporte_id == 2"
                    >mdi-worker</v-icon
                  >
                  <v-icon small left v-if="item.estado_reporte_id == 3"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  {{ formatoDinero(item.costo) }}
                </v-chip>
              </template>
              <span>
                {{ item.estado.estado }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.cliente`]="{ item }">
            <v-chip
              v-if="item.clienteEliminado"
              class="ma-1"
              outlined
              dark
              small
              color="black"
            >
              <v-icon left>mdi-emoticon-dead</v-icon>
              {{ item.nombreCliente }}</v-chip
            >
            <v-chip
              v-else
              class="ma-1"
              outlined
              dark
              small
              color="cyan"
              @click="abrirCliente(item.cliente_id)"
              ><v-icon left>mdi-account</v-icon>
              {{ item.nombreCliente }}</v-chip
            >
            <br />
            <v-chip
              class="ma-1"
              outlined
              dark
              small
              color="orange"
              @click="abrirTecnico(item.personal.id)"
              ><v-icon left>mdi-worker</v-icon>
              {{ item.personal.FullName }}</v-chip
            >
          </template>

          <template v-slot:[`item.fecha`]="{ item }">
            <v-icon left>mdi-calendar</v-icon>
            {{ formatoFecha(item.fecha) }}
            <br />
            <v-icon left>mdi-clock</v-icon>
            {{ formatoHora(item.hora) }}
          </template>
          <template v-slot:[`item.opciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="abrirChat(item)" icon v-on="on">
                  <v-icon small>mdi-chat</v-icon>
                </v-btn>
              </template>
              <span>Abrir Chat</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="detallesReporte(item)" icon v-on="on">
                  <v-icon small>mdi-card-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Detalles reporte</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="editarReporte(item)" icon v-on="on">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="eliminarReporte(item)" icon v-on="on">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <editarReporte ref="refReporteEditar"></editarReporte>
    <exportar ref="refExportar"></exportar>

    <nuevoReporte ref="refReporte"></nuevoReporte>
    <chat ref="refChat"></chat>
  </div>
</template>

<script>
import chat from "@/js/components/reportes/chat.vue";
import nuevoReporte from "@/js/components/reportes/nuevoReporte.vue";
import editarReporte from "@/js/components/reportes/editarReporte.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import LocalStorage from "@/js/LocalStorage.js";
import Common from "@/js/Common.js";

export default {
  components: {
    nuevoReporte,
    editarReporte,
    exportar,
    chat,
  },
  data() {
    return {
      //tabla chats
      search: "",
      headers: [
        { text: "Estado y costo", value: "costo" },
        { text: "Asunto", value: "asunto" },
        { text: "Cliente/Tecnico", value: "cliente" },
        { text: "Fecha", value: "fecha" },
        { text: "Opciones", value: "opciones" },
      ],
      estadoReportes: "1",
      elementos: [],
      ServicioActualizar: null,
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      loading: true,
      options: {},
      totalElementos: 0,
      zona_id: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.zona_id = arreUrl.pop();
    var filtroActual = LocalStorage.get("FILTRO_REPORTES");
    if (filtroActual) {
      this.estadoReportes = filtroActual;
    }
    var buscadorReportes = LocalStorage.get("BUSCADOR_REPORTES");
    if (buscadorReportes) {
      this.search = buscadorReportes;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    estadoReportes(val) {
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_REPORTES", val);
    },
    estadoReportes(val) {
      LocalStorage.set("FILTRO_REPORTES", val);
      this.getDatos();
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "reportes/datos/tabla",
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            filtro_status: this.estadoReportes,
            zona: this.zona_id,
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.reportes.data;
          this.totalElementos = result.data.reportes.total;
        });
    },

    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarReporte(item) {
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `reporte/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          }
        });
    },
    editarReporte(elemento) {
      this.reporteActualizar = elemento;
      const reporte = Object.assign({}, elemento);
      this.$refs.refReporteEditar.esperarDatos(reporte).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.reporteActualizar),
            1,
            confirm
          );
        }
      });
    },

    nuevoReporte() {
      this.$refs.refReporte.mostrar().then((confirm) => {
        if (confirm) {
          this.elementos.unshift(confirm);
        }
      });
    },
    abrirChat(item) {
      this.$refs.refChat
        .esperarDatos(item.nombreCliente, item.cliente_id, false)
        .then((confirm) => {});
    },
    detallesReporte(item) {
      document.location.href = "vista/detalles/ticket/" + item.id;
    },
    abrirCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + id;
    },
    abrirTecnico(id) {
      document.location.href = "/vista/persona/detalles/" + id;
    },
    calcularColor(id) {
      if (id == 1) {
        return "red";
      }
      if (id == 2) {
        return "amber";
      }
      if (id == 3) {
        return "green";
      }
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad);
    },
    formatoFecha(cantidad) {
      return Common.formatoFecha(cantidad);
    },
    formatoHora(cantidad) {
      return Common.formatoHora(cantidad);
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("tickets");
    },
  },
};
</script>
<style lang="css" scoped>
.gradiente {
  background: #eceff1;
}
</style>

