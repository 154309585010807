<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <apexchart
          height="350"
          type="donut"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <v-progress-circular
        v-else
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: [],
        title: {
          text: "Forma de pago",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
      },
      mostrar: false,
    };
  },
  mounted() {
    console.log("montado");
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          var datos = result.data.tipos;
          let tipos = datos.tipos;
          let serie = [];
          let etiquetas = [];
          for (let index = 0; index < tipos.length; index++) {
            serie.push(tipos[index].porcentaje);
            etiquetas.push(
              Common.formatMoney(tipos[index].recaudado) +
                " - " +
                tipos[index].tipo
            );
          }
          this.series = serie;
          this.chartOptions.labels = etiquetas;
          this.mostrar = true;
        });
    },
  },
};
</script>