<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-row>
          <v-card class="cool pa-4">
            <v-card-title class="display-1">
              <v-flex xs12 md6>
                <strong>Routers</strong>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs12 md5>
                <v-radio-group dense v-model="filtro" row>
                  <v-radio
                    dense
                    color="black"
                    label="Todos"
                    value="0"
                  ></v-radio>
                  <v-radio
                    dense
                    color="blue"
                    label="IP Publica"
                    value="1"
                  ></v-radio>
                  <v-radio
                    dense
                    color="green"
                    label="Script"
                    value="2"
                  ></v-radio>
                  <v-radio
                    dense
                    color="grey"
                    label="Ningúno"
                    value="3"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    @click="nuevoRouter()"
                    color="red"
                    class="rounded-pill"
                    dark
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Agregar
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    solo
                    class="rounded-pill"
                    v-model="search"
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="elementos"
                    :options.sync="options"
                    :server-items-length="totalElementos"
                    :loading="loading"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-format-horizontal-align-left',
                      lastIcon: 'mdi-format-horizontal-align-right',
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      itemsPerPageAllText: 'Todos',
                      itemsPerPageText: 'Registros por página:',
                      itemsPerPageOptions: [10, 50, 500, 1000, -1],
                    }"
                  >
                    <template v-slot:no-results>
                      <v-alert
                        :value="true"
                        color="lime lighten-1"
                        icon="mdi-alert"
                        >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                      >
                    </template>
                    <template v-slot:item.imagen="{ item }">
                      <v-img
                        class="cool-xs ma-1"
                        v-if="item.producto.imagen"
                        :src="item.producto.imagen"
                        height="80"
                        width="80"
                        ma-2
                      ></v-img>
                      <v-img
                        v-else
                        class="cool-xs ma-1"
                        src="/img/noimagen.jpg"
                        height="80"
                        width="80"
                      ></v-img>
                    </template>
                    <template v-slot:item.tipo="{ item }">
                      <v-chip v-if="item.tipo_conexion == 1" color="blue" dark>
                        IP Publica
                      </v-chip>

                      <v-chip v-if="item.tipo_conexion == 2" color="green" dark>
                        Script
                      </v-chip>
                      <v-chip v-if="item.tipo_conexion == 3" color="grey" dark>
                        Ningúno
                      </v-chip>
                    </template>
                    <template v-slot:item.ip="{ item }">
                      <v-btn color="primary" text @click="abrirIp(item.ip)">{{
                        item.ip
                      }}</v-btn>
                    </template>

                    <template v-slot:item.desconexion="{ item }">
                      <v-icon
                        left
                        large
                        :color="obtenerColorPorTiempo(item.desconexion)"
                        >mdi-timelapse</v-icon
                      >
                      <strong>{{ item.desconexion }}</strong>
                    </template>

                    <template v-slot:item.clientes="{ item }">
                      <v-icon left color="blue">mdi-account-multiple</v-icon>
                      <strong>{{ item.clientesCantidad }}</strong>
                    </template>
                    <template v-slot:item.opciones="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="detalles(item)" icon v-on="on">
                            <v-icon small> mdi-dots-horizontal </v-icon>
                          </v-btn>
                        </template>
                        <span>Ver detalles</span>
                      </v-tooltip>
                      <v-tooltip v-if="item.eliminado == false" top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="editar(item)" icon v-on="on">
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip v-if="item.eliminado == false" top>
                        <template v-slot:activator="{ on }">
                          <v-btn @click="eliminar(item)" icon v-on="on">
                            <v-icon small> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-alert
                        :value="true"
                        color="red"
                        dark
                        icon="mdi-alert-circle"
                      >
                        <v-row align="center">
                          <v-col> Ningún dato que mostrar :( </v-col>
                          <v-col class="shrink">
                            <v-btn @click="getDatos()"
                              ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-alert>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarRouter ref="refEditarRouter"></formEditarRouter>
      </div>
    </base-component>
  </div>
</template>

<script>
import formRouter from "@/js/components/red/routers/nuevo_router/pasosRegistroRouter.vue";
import formEditarRouter from "@/js/components/red/routers/editarRouter.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    formRouter,
    formEditarRouter,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "Imagen", value: "imagen", sortable: true },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Ip", value: "ip", sortable: true },
        { text: "Tipo", value: "tipo", sortable: true },
        { text: "Clientes", value: "clientes", sortable: false },
        { text: "Desconexion", value: "desconexion", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      RouterActualizar: {},
      filtro: "0",
    };
  },
  mounted() {
    console.log("montado");
    var filtroActual = LocalStorage.get("FILTRO_ROUTERS");
    if (filtroActual) {
      this.filtro = filtroActual;
    }
    var buscador = LocalStorage.get("BUSCADOR_ROUTERS");
    if (buscador) {
      this.search = buscador;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_ROUTERS", val);
    },

    filtro(val) {
      LocalStorage.set("FILTRO_ROUTERS", val);
      this.getDatos();
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `routers/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            filtro: this.filtro,
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.routers.data;
          this.totalElementos = result.data.routers.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `router/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },

    editar(elemento) {
      this.RouterActualizar = elemento;
      const router = Object.assign({}, elemento);
      this.$refs.refEditarRouter.esperarDatos(router).then((resultado) => {
        if (resultado) {
          this.getDatos();
        }
      });
    },
    testDeConexion(item) {
      this.$refs.confirmar
        .open(
          "Test de conexion",
          "Desea verificar la conexion a este Mikroik?",
          { color: "blue" }
        )
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("UsersModule/OnGet", {
                url: `text/conexion/mikrotik/` + item.id,
                data: {},
              })
              .then((result) => {
                if ([200, 201].includes(result.status)) {
                  var message = "Test exitoso :D";
                  this.$store.dispatch("notificaciones/add", {
                    tipo: "success",
                    mensaje: message,
                  });
                }
              });
          }
        });
    },
    guardarCambios() {},
    detalles(item) {
      document.location.href = "routers/router/" + item.id;
    },

    obtenerColorPorTiempo(tiempo) {
      // Dividir el tiempo en días, horas y minutos
      const partes = tiempo.match(/(\d+)\s*D|(\d+)\s*Hrs|(\d+)\s*Min/g);
      let dias = 0,
        horas = 0,
        minutos = 0;

      partes.forEach((parte) => {
        if (parte.includes("D")) {
          dias = parseInt(parte.replace("D", "").trim());
        } else if (parte.includes("Hrs")) {
          horas = parseInt(parte.replace("Hrs", "").trim());
        } else if (parte.includes("Min")) {
          minutos = parseInt(parte.replace("Min", "").trim());
        }
      });

      // Calcular el total de tiempo en minutos
      const totalMinutos = dias * 24 * 60 + horas * 60 + minutos;

      // Determinar el color basado en los minutos
      if (totalMinutos < 15) {
        return "green";
      } else if (totalMinutos >= 15 && totalMinutos < 60) {
        return "amber";
      } else {
        return "red";
      }
    },
    nuevoRouter() {
      document.location.href = "/registrar/nuevo/router";
    },
    abrirIp(ip) {
      window.open("http://" + ip, "_blank");
    },
  },
};
</script>
