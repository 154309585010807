<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-row>
          <v-col cols="12" md="3">
            <v-card class="cool pa-1">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Activos</v-list-item-subtitle>
                  <v-list-item-title class="display-1">
                    {{ clientes.activos }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar size="50" color="green" class="shadow-lg">
                  <v-icon dark>mdi-wifi</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="cool pa-1">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Suspendidos</v-list-item-subtitle>
                  <v-list-item-title class="display-1">
                    {{ clientes.suspendidos }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar size="50" color="red" class="shadow-lg">
                  <v-icon dark>mdi-wifi-off</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="cool pa-1">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Baja temporal</v-list-item-subtitle>
                  <v-list-item-title class="display-1">
                    {{ clientes.baja }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                  size="50"
                  color="grey"
                  class="shadow-lg"
                  dark
                >
                  <v-icon dark>mdi-account-off</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="cool pa-1">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle>Clientes totales</v-list-item-subtitle>
                  <v-list-item-title class="display-1">
                    {{ clientes.total }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                  size="50"
                  color="indigo "
                  class="shadow-lg"
                  dark
                >
                  <v-icon dark>mdi-account-multiple-check</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-layout row wrap>
          <v-flex class="cool pa-1" xs12 md6>
            <graficaTipoCliente></graficaTipoCliente>
          </v-flex>
          <v-flex class="cool pa-1" xs12 md6>
            <graficaCaptacionCliente></graficaCaptacionCliente>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex class="pa-2" xs12>
            <graficaClientesZona></graficaClientesZona>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex class="pa-2" xs12>
            <graficaClientesServicios></graficaClientesServicios>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import graficaTipoCliente from "./graficaTipoCliente.vue";
import graficaCaptacionCliente from "./graficaCaptacionCliente.vue";
import graficaClientesZonas from "./graficaClientesZona.vue";
import graficaClientesServicios from "./graficaClientesServicios.vue";
import Common from "@/js/Common";
export default {
  components: {
    apexchart,
    graficaTipoCliente,
    graficaClientesServicios,
    graficaCaptacionCliente,
    graficaClientesZonas,
  },
  data() {
    return {
      clientes: {
        activos: 0,
        suspendidos: 0,
        baja: 0,
        total: 0,
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "estadisticas/clientes",
          data: {},
        })
        .then((result) => {
          this.clientes = result.data.clientes;
        });
    },
  },
};
</script>