<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-card flat>
      <v-card-subtitle>
        <v-radio-group dense v-model="estadoReportes" row>
          <v-radio dense color="black" label="Todos" value="0"></v-radio>

          <v-radio dense color="red" label="Pendientes" value="1"></v-radio>
          <v-radio dense color="yellow" label="En proceso" value="2"></v-radio>
          <v-radio dense color="green" label="Solucionados" value="3"></v-radio>
        </v-radio-group>
      </v-card-subtitle>
      <v-card-subtitle>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-btn
              dense
              @click="nuevoReporte()"
              class="rounded-pill pa-3 mr-2"
              color="red"
              dark
            >
              <v-icon left>mdi-ticket-account</v-icon>
              Agregar
            </v-btn>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md6>
            <v-text-field
              v-model="search_tickets"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              solo
              rounded
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-subtitle>

      <v-card-text>
        <v-data-table
          :headers="headers_tickets"
          :items="tickets"
          :search="search_tickets"
        >
          <template v-slot:[`item.chat`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="abrirChat(item)" icon v-on="on">
                  <v-icon small>mdi-chat</v-icon>
                </v-btn>
              </template>
              <span>Abrir Chat</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.cliente`]="{ item }">
            <v-chip
              outlined
              v-if="item.cliente.eliminado == 0"
              dark
              small
              color="cyan"
              @click="abrirCliente(item.cliente)"
              ><v-icon left>mdi-account</v-icon>
              {{ item.cliente.FullName }}</v-chip
            >
            <v-chip v-else outlined dark small color="black">
              <v-icon left>mdi-emoticon-dead</v-icon>
              {{ item.cliente.FullName }}</v-chip
            >
          </template>
          <template v-slot:[`item.estado_reporte_id`]="{ item }">
            <v-chip
              @click="cambiarEstado(item)"
              :color="asignarColor(item.estado_reporte_id).color"
              small
              dark
              >{{ asignarColor(item.estado_reporte_id).texto }}</v-chip
            >
          </template>
          <template v-slot:[`item.opciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="eliminarReporte(item)" icon v-on="on">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="editarReporte(item)" icon v-on="on">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="detallesReporte(item)" icon v-on="on">
                  <v-icon small>mdi-card-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Detalles reporte</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogEstados" max-width="300px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Cambiar Estado </v-card-title>
          <v-container grid-list-md>
            <v-form v-model="validEstados" ref="formEstados" lazy-validation>
              <v-flex xs12>
                <v-select
                  label="Status"
                  v-model="estado_id"
                  :rules="generalRegla"
                  item-text="estado"
                  item-value="id"
                  :items="estados"
                ></v-select>
              </v-flex>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogEstados = false">Cancelar</v-btn>
            <v-btn
              @click="actualizarEstado()"
              class="rounded-pill pa-3"
              color="primary"
              >Cambiar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <editarReporte ref="refReporteEditar"></editarReporte>
    <exportar ref="refExportar"></exportar>

    <nuevoReporte ref="refReporte"></nuevoReporte>
    <chat ref="refChat"></chat>
  </div>
</template>
  
  <script>
import chat from "@/js/components/reportes/chat.vue";
import nuevoReporte from "@/js/components/reportes/nuevoReporte.vue";
import editarReporte from "@/js/components/reportes/editarReporte.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    nuevoReporte,
    editarReporte,
    exportar,
    chat,
  },
  data() {
    return {
      //tabla chats
      search_tickets: "",
      headers_tickets: [
        { text: "Abrir Chat", value: "chat" },
        { text: "Asunto", value: "asunto" },
        { text: "Cliente", value: "cliente" },
        { text: "Estatus", value: "estado_reporte_id" },
        { text: "Opciones", value: "opciones" },
      ],
      estadoReportes: "1",
      tickets: [],
      ServicioActualizar: null,
      estados: [],
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "1",
      id: 0,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    var filtroActual = LocalStorage.get("FILTRO_REPORTES_CLIENTE");
    if (filtroActual) {
      this.estadoReportes = filtroActual;
    }
    this.getReportes();
    this.getEstados();
  },
  watch: {
    estadoReportes(val) {
      LocalStorage.set("FILTRO_REPORTES_CLIENTE", val);

      this.getReportes();
    },
  },
  computed: {},
  methods: {
    getReportes() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "reportes/todos/cliente/" + this.id,
          data: {
            filtro: this.estadoReportes,
          },
        })
        .then((result) => {
          this.tickets = result.data.reportes;
        });
    },

    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "estados/reporte",
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },

    eliminarReporte(item) {
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `reporte/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.tickets.splice(this.tickets.indexOf(item), 1);
              });
          }
        });
    },
    editarReporte(elemento) {
      this.reporteActualizar = elemento;
      const reporte = Object.assign({}, elemento);
      this.$refs.refReporteEditar.esperarDatos(reporte).then((confirm) => {
        if (confirm) {
          this.tickets.splice(
            this.tickets.indexOf(this.reporteActualizar),
            1,
            confirm
          );
        }
      });
    },
    cambiarEstado(item) {
      this.item = item;

      this.estado_id = item.estado_reporte_id;
      this.dialogEstados = true;
    },
    actualizarEstado() {
      if (this.$refs.formEstados.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `reporte/cambiar/estado/` + this.item.id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {
              estado_reporte_id: this.estado_id,
            },
          })
          .then((result) => {
            this.tickets.splice(
              this.tickets.indexOf(this.item),
              1,
              result.data.reporte
            );
            this.dialogEstados = false;
          });
      }
    },
    nuevoReporte() {
      this.$refs.refReporte.mostrar().then((confirm) => {
        if (confirm) {
          this.tickets.unshift(confirm);
        }
      });
    },
    abrirChat(item) {
      this.$refs.refChat
        .esperarDatos(item.cliente.FullName, item.cliente.id, false)
        .then((confirm) => {});
    },
    detallesReporte(item) {
      document.location.href = "vista/detalles/ticket/" + item.id;
    },
    abrirCliente(cliente) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + cliente.id;
    },
    asignarColor(id) {
      if (id == 1) {
        return { color: "red", texto: "Pendiente" };
      }
      if (id == 2) {
        return { color: "yellow", texto: "En proceso" };
      }
      if (id == 3) {
        return { color: "green", texto: "Solucionado" };
      }
      return { color: "black", texto: "error" };
    },
  },
};
</script>
  <style lang="css" scoped>
.gradiente {
  background: #eceff1;
}
</style>
  
  