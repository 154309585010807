class Negocio {
    constructor() {
        this.nombre = "";
        this.codigo_postal = "";
        this.direccion = "";
        this.estado = "";
        this.municipio = "";
        this.colonia = "";
        this.longitud = "";
        this.latitud = "";
        this.telefono = "";
        this.correo = "";
        this.celular = "";
        this.persona_id = "";
        this.usuario_recargas = "";
        this.contraseña_recargas = "";
        this.costo = "";
        this.tipo_negocio_id = "";
        this.detalles = "";
        this.empresa_id = "";
        this.eliminado = false;
    }
}

export default Negocio