<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Actividades</strong>
                <v-spacer></v-spacer>
                <v-flex class="d-flex justify-center" xs12 md6>
                  <v-radio-group dense v-model="filtroStatus" row>
                    <v-radio
                      dense
                      color="red"
                      label="Pendientes"
                      value="1"
                    ></v-radio>
                    <v-radio
                      dense
                      color="amber"
                      label="En proceso"
                      value="2"
                    ></v-radio>
                    <v-radio
                      dense
                      color="green"
                      label="Solucionados"
                      value="3"
                    ></v-radio>
                    <v-radio
                      dense
                      color="black"
                      label="Todos"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      dense
                      @click="nuevo()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Nuevo
                    </v-btn>
                    <v-btn
                      @click="exportar()"
                      color="red"
                      class="rounded-pill"
                      dark
                    >
                      <v-icon left>mdi-file-export</v-icon>
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [8, 100, 500, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>

                <template v-slot:item.estado="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="ma-2"
                        v-bind="attrs"
                        v-on="on"
                        :color="calcularColor(item.estado_id)"
                        dark
                      >
                        <v-icon small left v-if="item.estado_id == 1"
                          >mdi-timer</v-icon
                        >
                        <v-icon small left v-if="item.estado_id == 2"
                          >mdi-worker</v-icon
                        >
                        <v-icon small left v-if="item.estado_id == 3"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        {{ formatoDinero(item.costo) }}
                      </v-chip>
                    </template>
                    <span>
                      {{ item.estado.estado }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.fecha`]="{ item }">
                  <v-icon left>mdi-calendar</v-icon>
                  {{ formatoFecha(item.fecha) }}
                </template>
                <template v-slot:item.hora="{ item }">
                  <div class="ma-1">
                    <v-icon left>mdi-timer</v-icon>
                    {{ formatoHora(item.hora_inicio) }}
                  </div>
                  <div class="ma-1">
                    <v-icon left>mdi-timer-off</v-icon>
                    {{ formatoHora(item.hora_fin) }}
                  </div>
                </template>
                <template v-slot:item.personal="{ item }">
                  <v-chip
                    small
                    @click="verPersonal(item.personal_asignado_id)"
                    color="orange"
                    outlined
                  >
                    <v-icon left>mdi-worker</v-icon>
                    {{ item.nombrePersonal }}
                  </v-chip>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="detalles(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="editar(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="eliminar(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <exportar ref="refExportar"></exportar>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <editarActividadComponent
          ref="refActividadEditar"
        ></editarActividadComponent>
        <formActividad ref="refActividad"></formActividad>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import formActividad from "@/js/components/actividades/nuevaActividad";
import editarActividadComponent from "@/js/components/actividades/editarActividad";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    formActividad,
    editarActividadComponent,
    exportar,
  },
  data() {
    return {
      filtroStatus: "1",
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "Estado y costo", value: "estado", sortable: false },
        { text: "Trabajo", value: "trabajo", sortable: true },
        { text: "Fecha", value: "fecha", sortable: true },
        { text: "Hora inicio/fin", value: "hora", sortable: true },
        { text: "Personal", value: "personal", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var filtroActual = LocalStorage.get("FILTRO_ACTIVIDADES");
    if (filtroActual) {
      this.filtroStatus = filtroActual;
    }
    var buscadorReportes = LocalStorage.get("BUSCADOR_ACTIVIDADES");
    if (buscadorReportes) {
      this.search = buscadorReportes;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_ACTIVIDADES", val);
    },
    filtroStatus(val) {
      LocalStorage.set("FILTRO_ACTIVIDADES", val);
      this.getDatos();
    },
  },

  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "actividades/datos/tabla",
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            filtro_status: this.filtroStatus,
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.actividades.data;
          this.totalElementos = result.data.actividades.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    nuevo() {
      this.$refs.refActividad.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
        }
      });
    },

    editar(elemento) {
      this.actividadActualizar = elemento;
      const actividad = Object.assign({}, elemento);
      this.$refs.refActividadEditar.esperarDatos(actividad).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.actividadActualizar),
            1,
            confirm
          );
        }
      });
    },
    eliminar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `actividad/` + item.id;
            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          }
        });
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("actividades");
    },
    detalles(item) {
      document.location.href = "vista/detalles/actividad/" + item.id;
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad);
    },
    formatoFecha(cantidad) {
      return Common.formatoFecha(cantidad);
    },
    formatoHora(cantidad) {
      return Common.formatoHora(cantidad);
    },
    verPersonal(id) {
      LocalStorage.set("TAB_ACTUAL_PERSONAL", 0);
      document.location.href = "vista/persona/detalles/" + id;
    },
    calcularColor(id) {
      if (id == 1) {
        return "red";
      }
      if (id == 2) {
        return "amber";
      }
      if (id == 3) {
        return "green";
      }
    },
  },
};
</script>