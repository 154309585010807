<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="cool pa-4">
          <v-card-title class="display-1">
            <strong>Sectores y enlaces</strong>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-radio-group dense v-model="filtro" row>
                <v-radio dense color="black" label="Todos" value="0"></v-radio>
                <v-radio
                  dense
                  color="cyan"
                  label="Punto de acceso"
                  value="1"
                ></v-radio>
                <v-radio
                  dense
                  color="orange"
                  label="Estacion"
                  value="2"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn @click="nuevo()" color="red" class="rounded-pill" dark>
                  <v-icon left>mdi-plus</v-icon>
                  Agregar
                </v-btn>
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  v-model="search"
                  @keyup.enter="getDatos"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  class="rounded-pill"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="elementos"
            :options.sync="options"
            :server-items-length="totalElementos"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [10, 100, 500, 1000, -1],
            }"
          >
            <template v-slot:no-results>
              <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
              >
            </template>
            <template v-slot:item.imagen="{ item }">
              <v-flex xs12>
                <v-img
                  class="cool-xs ma-1"
                  v-if="item.cpe.imagen"
                  :src="item.cpe.imagen"
                  height="60"
                  width="60"
                  ma-2
                ></v-img>
                <v-img
                  v-else
                  class="cool-xs ma-1"
                  src="/img/noimagen.jpg"
                  height="60"
                  width="60"
                ></v-img>
              </v-flex>
            </template>
            <template v-slot:item.antena="{ item }">
              <strong>
                {{ item.cpe.producto }}
              </strong>
              <br />
              {{ item.cpe.marca }}
            </template>
            <template v-slot:item.modo="{ item }">
              <v-chip v-if="item.ap_id" color="orange" dark>
                <v-icon left>mdi-volume-low</v-icon>
                Estación</v-chip
              >
              <v-chip v-else color="cyan" dark>
                <v-icon left>mdi-access-point-network</v-icon>
                Punto de acceso
              </v-chip>
            </template>
            <template v-slot:item.torre="{ item }">
              <v-chip v-if="item.torre" small color="grey" dark outlined>
                <v-icon left>mdi-radio-tower</v-icon>
                {{ item.torre.nombre }}</v-chip
              >
            </template>
            <template v-slot:item.ip="{ item }">
              <v-btn
                v-if="item.ip"
                text
                color="primary"
                @click="abrirIp(item.ip)"
              >
                {{ item.ip }}
              </v-btn>
              <v-btn v-else text color="indigo" @click="abrirIp(item.ip)">
                Pendiente
              </v-btn>
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="detalles(item)" icon v-on="on">
                    <v-icon small> mdi-dots-horizontal </v-icon>
                  </v-btn>
                </template>
                <span>Detalles</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editarAntena(item)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar(item)" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                <v-row align="center">
                  <v-col> Ningún dato que mostrar :( </v-col>
                  <v-col class="shrink">
                    <v-btn @click="getDatos()">
                      <v-icon pl-4>mdi-cached</v-icon> Refrescar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <editarAntena ref="refEditarAntena"></editarAntena>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <moverDeRouter ref="moverRouter"></moverDeRouter>
    <nuevoAntena ref="nuevoAntena"></nuevoAntena>
  </div>
</template>

<script>
import moverDeRouter from "@/js/components/acciones_masivas/moverDeRouter.vue";
import formEditarAntena from "@/js/components/red/antenas/editarAntena.vue";
import nuevoAntena from "@/js/components/red/enlaces/nuevoAntena.vue";
import editarAntena from "@/js/components/red/enlaces/editarAntenaEnlace.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    nuevoAntena,
    formEditarAntena,
    editarAntena,
    moverDeRouter,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Imagen", value: "imagen", sortable: false },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Antena", value: "antena", sortable: false },
        { text: "Modo", value: "modo", sortable: false },
        { text: "Torre", value: "torre", sortable: false },
        { text: "Ip", value: "ip", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      filtro: "0",
      torre_id: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.torre_id = arreUrl.pop();
    var buscador = LocalStorage.get("BUSCADOR_ENLACES");
    if (buscador) {
      this.search = buscador;
    }
    var filtroActual = LocalStorage.get("FILTRO_ENLACES");
    if (filtroActual) {
      this.filtro = filtroActual;
    }
  },
  watch: {
    filtro(val) {
      this.getDatos();
    },
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    search(val) {
      LocalStorage.set("BUSCADOR_ENLACES", val);
    },

    filtro(val) {
      LocalStorage.set("FILTRO_ENLACES", val);
      this.getDatos();
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `antenas/enlaces/tabla/torre/` + this.torre_id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            filtro: this.filtro,
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.loading = false;
            this.elementos = result.data.antenas.data;

            this.totalElementos = result.data.antenas.total;
          }
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },

    nuevo() {
      this.$refs.nuevoAntena.mostrar().then((confirm) => {
        if (confirm) {
          this.elementos.unshift(confirm);
        }
      });
    },

    editarAntena(item) {
      this.antenaActualizar = item;
      let antena = Object.assign({}, item);
      this.$refs.refEditarAntena.mostrar(antena).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.antenaActualizar),
            1,
            confirm
          );
        }
      });
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `antena/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `antena/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },
    moverDeZona() {
      this.$refs.moverZona.cargarDatos(this.selected).then((resultado) => {
        if (resultado) {
          this.getDatos();
          this.selected = [];
        }
      });
    },
    moverDeRouter() {
      this.$refs.moverRouter.cargarDatos(this.selected).then((resultado) => {
        if (resultado) {
          this.getDatos();
          this.selected = [];
        }
      });
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    detalles(antena) {
      LocalStorage.set("TAB_ACTUAL_ANTENA", 0);
      document.location.href = "detalles/antena/vista/" + antena.id;
    },
  },
};
</script>
