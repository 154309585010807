<template>
  <div>
    <v-layout v-if="zona" row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-card-title primary-title>
                  <strong>{{ zona.nombre }}</strong>
                </v-card-title>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        zona.clientesCantidad
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad de clientes</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-speedometer </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        zona.serviciosCantidad
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Total de servicios</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-radio-tower </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>23</v-list-item-title>
                      <v-list-item-subtitle
                        >Cantidad de torres en la zona
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-coin </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        dinero(zona.ganancias)
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Ganancias de la zona al mes
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs12 md8>
                <mapaZona
                  class="cool pr-4"
                  v-if="mostrarMapa"
                  ref="mapa"
                ></mapaZona>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Common from "@/js/Common";
import mapaZona from "@/js/components/red/zonas/detalles/mapaZona.vue";
export default {
  components: {
    mapaZona,
  },
  data() {
    return {
      zona: null,
      mostrarMapa: true,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
  },
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.zona.clientes,
        this.zona.latitud,
        this.zona.longitud,
        "markerTorre.png",
        this.zona.nombre,
        false
      );
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zona/` + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.zona = result.data.zona;
            if (this.zona.latitud && this.zona.longitud) {
              this.esperarComponente();
            }
          }
        });
    },
    dinero(monto) {
      return Common.formatMoney(monto);
    },
  },
};
</script>