<template>
  <div>
    <v-card class="cool pa-3">
      <div v-if="mostrar" id="chart">
        <div id="chart">
          <apexchart
            type="scatter"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
      <v-progress-circular
        v-else
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "scatter",
          zoom: {
            enabled: false,
            type: "x",
          },
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Tiempo del cliente con relacion al total de sus pagos liquidados",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
        xaxis: {
          title: {
            text: "Meses",
          },
        },
        yaxis: {
          title: {
            text: "Ganancias",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val);
            },
          },
        },
      },

      mostrar: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `graficas/tiempo/ganancias/clientes`,
          data: {},
        })
        .then((result) => {
          this.series = result.data.grafica;
          /*
          //this.chartOptions.xaxis.categories = paquetesNombres;
          */
          this.mostrar = true;
        });
    },
  },
};
</script>