<template>
  <div slot="component">
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Editar</strong>
          </v-card-title>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="500"
          >
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Nombre de la torre"
                      v-model="torre.nombre"
                      :rules="generalRegla"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Encargado"
                      v-model="torre.encargado"
                      :rules="generalRegla"
                      outlined
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Telefono"
                      v-model="torre.telefono"
                      outlined
                      prepend-icon="mdi-deskphone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Celular"
                      v-model="torre.celular"
                      outlined
                      prepend-icon="mdi-cellphone"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-select
                      v-model="torre.zona_id"
                      :items="zonas"
                      :rules="generalRegla"
                      attach
                      outlined
                      label="Zona"
                      item-value="id"
                      item-text="nombre"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevaZona()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              >Agregar otra zona</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Dirección"
                      v-model="torre.direccion"
                      :rules="generalRegla"
                      outlined
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-textarea
                      filled
                      label="Detalles"
                      v-model="torre.detalles"
                      rows="3"
                    ></v-textarea>
                  </v-flex>
                </v-layout>

                <v-col cols="12">
                  <ubicacion ref="mapa"></ubicacion>
                </v-col>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              color="primary"
              @click="actualizar()"
              class="rounded-pill pa-3"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <formNuevaZona ref="zona"></formNuevaZona>
  </div>
</template>
<script>
import ubicacion from "@/js/components/mapa/mapaComponent.vue";
import formNuevaZona from "@/js/components/red/zonas/nuevaZona.vue";
import Torre from "@/js/components/red/torres/Torre.js";

export default {
  components: {
    ubicacion,
    formNuevaZona,
  },
  data() {
    return {
      dialog: false,
      torre: new Torre(),
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      reject: null,
      resolve: null,
      isUpdating: false,
      zonas: [],
    };
  },
  mounted() {
    this.getDatos();
    console.log("torres montadas");
  },
  watch: {},
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.torre.latitud,
        this.torre.longitud,
        "markerTorre.png",
        "Zona",
        true
      );
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zonas/empresa/todas`,
          data: {},
        })
        .then((result) => {
          this.zonas = result.data.zonas;
        });
    },
    esperarDatos(elemento) {
      console.log("elemento: ", elemento);
      this.torre = elemento;
      this.dialog = true;
      this.esperarComponente();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        this.torre.latitud = this.$refs.mapa.getLatitud();
        this.torre.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `torre/` + this.torre.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.torre,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.torre);
          });
      }
    },
    nuevaZona() {
      this.$refs.zona.mostrar().then((resultado) => {
        if (resultado) {
          this.zonas.push(resultado);
          this.torre.zona_id = resultado.id;
        }
      });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>