<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title>
                <strong>Detalles Negocio</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-map-marker</v-icon>
                  Datos
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-arrow-up-bold-circle</v-icon>
                  Ingresos</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-arrow-down-bold-circle</v-icon>
                  Egresos</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <datos></datos>
                  </v-tab-item>
                  <v-tab-item>
                    <ingresos></ingresos>
                  </v-tab-item>
                  <v-tab-item>
                    <egresos></egresos>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import datos from "@/js/components/negocios/detalles/datosNegocio.vue";
import ingresos from "@/js/components/negocios/detalles/ingresosNegocio.vue";
import egresos from "@/js/components/negocios/detalles/egresosNegocio.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    datos,
    ingresos,
    egresos,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_NEGOCIO", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_NEGOCIO");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>